import { useEffect, useRef, useState } from 'react'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { hoursMinsSecs } from '../util/time'
import useUserIsCurrentRoomAdmin from '../hooks/useUserIsCurrentRoomAdmin'

const cdnUrl = process.env.REACT_APP_CDN_URL

export default function Player() {

    const appState = useAppContext()
    const dispatch = useAppDispatch()

    const [errorTask, setErrorTask] = useState(0)

    const { queue, playMode } = appState
    const [ nowPlaying ] = queue
    const [ failTime, setFailTime ] = useState(0)
    const [ playing, setPlaying ] = useState(false)
    const [ audio ] = useState(new Audio())
    const [ ignoreEnd, setIgnoreEnd ] = useState(false)
    const [ hd, setHd ] = useState(true)
    const [ playHeadTime, setPlayHeadTime ] = useState('0:00')
    const [ playHeadPercent, setPlayHeadPercent ] = useState(0)

    const track = nowPlaying?.track
    const nowPlayingHash = track?.hash
    const playTime = nowPlaying?.playTime
    const now = Date.now()
    const startPosition = (now - (playTime ?? now)) / 1000

    const audioRef = useRef()
    audioRef.current = audio
    const nowPlayingRef = useRef()
    nowPlayingRef.current = nowPlaying
    const playModeRef = useRef()
    playModeRef.current = playMode
    const trackRef = useRef()
    trackRef.current = track

    const userIsRoomAdmin = useUserIsCurrentRoomAdmin()

    useEffect(() => {
        audio.addEventListener('ended', playEnded)
        audio.addEventListener('error', playError)
        audio.addEventListener('play', playStarted)
        audio.addEventListener('pause', playStopped)
        audio.addEventListener('abort', playAborted)

        return () => {
            audio.removeEventListener('ended', playEnded)
            audio.removeEventListener('error', playError)
            audio.removeEventListener('play', playStarted)
            audio.removeEventListener('pause', playStopped)
            audio.removeEventListener('abort', playAborted)
        }
    }, [])

    useEffect(() => {
        if (errorTask) {
            clearTimeout(errorTask)
            setErrorTask(0)
        }
        let updateTask
        if (audio && track) {
            console.log(`track changed.  playing ${track.title} by ${track.artist}: ${trackUrl(track)}`)
            audio.autoplay = true
            audio.src = trackUrl(track)
            audio.currentTime = startPosition
            updateTask = setInterval(updatePlaybackTime, 250)
        }
        if (updateTask) {
            return () => { clearInterval(updateTask) }
        }
    }, [nowPlayingHash, playTime, hd])

    useEffect(() => {
    }, [])

    function playAborted(e) {
        console.log('media load aborted')
    }

    function playError(e) {
        console.error(`play error`)
        const a = audioRef.current
        setFailTime(a.currentTime)
        setErrorTask(setTimeout(restartPlayer, 3000))
    }

    function playEnded(e) {
        // Aborts are also paired with ended events, so we have to ignore one of them
        // if we got an abort
        if (ignoreEnd) {
            console.log('ignoring track end because of previous abort')
            setIgnoreEnd(false)
            return
        }
        const t = trackRef.current
        const m = playModeRef.current
        const a = audioRef.current
        console.log(`play ended event for ${t.title} at ${a.currentTime}`)
        if (m === 'local') {
            console.log(`local play mode.  advancing queue from: ${t.title}`)
            dispatch(Actions.advanceQueue())
        } else {
            console.log(`reporting track ended: ${t.title}`)
            global.chatRoom.trackEnded(t)
        }
    }

    function playStarted(e) {
        const t = trackRef.current
        console.log(`play started: ${t.title}`)
        setPlaying(true)
    }

    function playStopped(e) {
        const t = trackRef.current
        console.log(`play stopped: ${t.title}`)
        setPlaying(false)
    }

    function restartPlayer() {
        if (!track) return
        console.log('restarting player')
        audio.src = null
        audio.src = trackUrl(track)
        audio.currentTime = failTime
        audio.play().catch( err => setErrorTask(setTimeout(restartPlayer, 3000)))
        console.log(`updated ${audio}`)
    }

    function skip() {
        if (playMode !== 'local') {
            global.chatRoom.removeTrack(nowPlaying)
        } else {
            dispatch(Actions.removeFromQueue(0))
        }
    }

    function syncPlayback() {
        const a = audioRef.current
        const m = playModeRef.current
        const n = nowPlayingRef.current
        if (m === 'local') {
            audio?.play()
        } else {
            global.chatRoom.getCurrentQueue()
            a.currentTime = (Date.now() - n.playTime) / 1000
            a.play()
        }
    }

    function togglePlay() {
        if (audio?.paused) {
            syncPlayback()
        } else {
            audio?.pause()
        }
    }

    function toggleQuality() {
        setHd( old => !old )
    }

    function trackUrl(track) {
        if (!track) return ''
        const hq = track.files?.find( file => file.key.endsWith('hq.m4a') )
        const lq = track.files?.find( file => file.key.endsWith('lq.m4a') )
        let url
        if (hd) {
            if (hq) {
                url = `${cdnUrl}${hq.key}`
            } else if (lq) {
                url = `${cdnUrl}${lq.key}`
            } else {
                url = `${cdnUrl}${track.hash}/hq.m4a`
            }
        } else {
            if (lq) {
                url = `${cdnUrl}${lq.key}`
            } else {
                url = `${cdnUrl}${track.hash}/lq.m4a`
            }
        }
        return url
    }

    function updatePlaybackTime() {
        const a = audioRef.current
        setPlayHeadTime(hoursMinsSecs(a.currentTime))
        setPlayHeadPercent(a.currentTime / a.duration)
    }

    let skipButton
    if (playMode === 'local' || userIsRoomAdmin)
        skipButton = <span className="material-symbols-outlined TrackControlButton" onClick={skip}>skip_next</span>

    return (
        <div className="Player">
            <div className="PlayerGrid">
                <div className="PlayerTrackInfo">
                    <div className="TrackTitle" onClick={() => dispatch(Actions.showSheet({ action: 'showTrack', data: track }))}>{ track?.title }</div>
                    <div className="TrackArtist">{ track?.artist }</div>
                </div>
                <div className="PlayerControls">
                    <span className="material-symbols-outlined TrackControlButton" onClick={togglePlay}>{ playing ? 'stop_circle' : 'play_circle' }</span>
                    <span className="material-symbols-outlined TrackControlButton" onClick={toggleQuality}>{ hd ? 'hd' : 'sd' }</span>
                    <span className="material-symbols-outlined TrackControlButton">{ playMode === 'local' ? 'person' : 'group' }</span>
                    { skipButton }
                    <div className="PlayerTime">{ playHeadTime } / { hoursMinsSecs(audio.duration) }</div>
                </div>
                <div className="PlayerProgress">
                    <div className="PlayerProgressBar" style={{ width: `${playHeadPercent * 100}%`}}/>
                </div>
            </div>
        </div>
    )
}
