import { useEffect, useState } from 'react'
import { createSHA256 } from 'hash-wasm'
import { useAppContext, useAppDispatch } from './AppContext'
import { Actions } from './AppReducer'
import { getRoomData, getUserData } from './api/Api'
import Chat from './chat/Chat'
import Listen from './listen/Listen'
import MainModal from './MainModal'
import MainSheet from './MainSheet'
import Player from './player/Player'
import Profile from './profile/Profile'
import Search from './search/Search'
import Upload from './upload/Upload'
import Toast from './widgets/Toast'
import defaultProfileIcon from './img/default-profile.jpg'
import './App.css'

function Main() {

    const appState = useAppContext()
    const dispatch = useAppDispatch()
    global.uiDispatch = dispatch // A cheat to allow us to dispatch directly from Api functions

    const [avatarUrl, setAvatarUrl] = useState('')

    useEffect(() => {
        getUserData()
        getRoomData()
    }, [])

    const { navigateTo } = Actions

    useEffect(() => {
        async function getAvatarUrl() {
            console.log(`getAvatarUrl for user: ${JSON.stringify(appState.user)}`)
            if (appState.user) {
                const hashedEmail = (await createSHA256())
                    .update(appState.user?.email?.trim().toLowerCase() ?? '')
                    .digest('hex')
                setAvatarUrl(`https://www.gravatar.com/avatar/${hashedEmail}?d=404`)
                console.log(`getAvatarUrl: https://www.gravatar.com/avatar/${hashedEmail}`)
            }
        }
        getAvatarUrl()
    }, [appState.user])

    const tabs = [
        <div className={ appState.activeTab === 'search' ? 'ActiveTab' : 'InactiveTab' } key={2}> <Search/> </div>,
        <div className={ appState.activeTab === 'listen' ? 'ActiveTab' : 'InactiveTab' } key={3}> <Listen/> </div>,
        <div className={ appState.activeTab === 'chat' ? 'ActiveTab' : 'InactiveTab' } key={4}> <Chat/> </div>,
        <div className={ appState.activeTab === 'profile' ? 'ActiveTab' : 'InactiveTab' } key={5}> <Profile/> </div>,
        <div className={ appState.activeTab === 'upload' ? 'ActiveTab' : 'InactiveTab' } key={1}> <Upload/> </div>
    ]

    return (
        <div className="MainContainer">
            <div className="Main">
                <div className="MainContent">
                    <div className="MainHeader">
                        <div className="MainTabs">
                            <div className={appState.activeTab === 'search' ? 'MainTab Active' : 'MainTab'} onClick={ () => dispatch(navigateTo('search')) }>Search</div>
                            <div className={appState.activeTab === 'listen' ? 'MainTab Active' : 'MainTab'} onClick={ () => dispatch(navigateTo('listen')) }>Listen</div>
                            <div className={appState.activeTab === 'chat' ? 'MainTab Active' : 'MainTab'} onClick={ () => dispatch(navigateTo('chat')) }>Chat</div>
                            <div className={appState.activeTab === 'upload' ? 'MainTab Active' : 'MainTab'} onClick={ () => dispatch(navigateTo('upload')) }>Upload</div>
                        </div>
                        <div className="MainButtons">
                            <img className="ProfileAvatar"
                                 src={avatarUrl}
                                 alt="User Avatar"
                                 onClick={() => dispatch(navigateTo('profile'))}
                                 onError={() => setAvatarUrl(defaultProfileIcon)}
                            />
                        </div>
                    </div>
                    <div className="MainTabContent">
                        <div className="MainTabContentScroller">
                        { tabs }
                        </div>
                    </div>
                    <div className="MainContentCover" style={{ visibility: appState.mainSheetOpen ? 'visible' : 'hidden' }}/>
                    <MainSheet />
                    <MainModal />
                    <Toast />
                </div>
                <Player className="Player" />
            </div>
        </div>
    )
}

export default Main
