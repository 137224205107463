import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { addMusicByHash } from '../api/Api'
import useQueueTrack from '../hooks/useQueueTrack'

export default function TrackControls(props) {

    const dispatch = useAppDispatch()
    const queueTrack = useQueueTrack()

    const { track, addToRoom } = props

    async function addTrack() {
        const added = await addMusicByHash(addToRoom, track.hash)
        if (added) {
            dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the room.</span>}))
        } else {
            dispatch(Actions.showToast({content: <span>Could not add <i>{track.title}</i> to the room.</span>}))
        }
    }

    function handleAdd() {
        if (addToRoom) {
            addTrack()
        } else {
            queueTrack(track)
        }
    }

    return (
        <div className="TrackControlButtons">
            <span className="material-symbols-outlined TrackControlButton" onClick={(e) => dispatch(Actions.playTrack(track))}>play_circle</span>
            <span className="material-symbols-outlined TrackControlButton" onClick={handleAdd}>add_circle</span>
        </div>
    )
}