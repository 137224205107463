import { useEffect, useRef, useState } from 'react'
import WebSocketManager from './WebSocketManager'
import { left, right } from '../util/text'
import { idleTime } from '../util/time'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions as Action, Actions } from '../AppReducer'
import ChatContainer from './ChatContainer'

const chatHelp = [
    'Valid commands:',
    '/help -- show help',
    '/who  -- show connected users'
]

export default function Chat() {

    const dispatch = useAppDispatch()
    const appState = useAppContext()
    const { rooms, user, playMode } = appState

    const [commandHistory, setCommandHistory] = useState([])
    const [connected, setConnected] = useState(false)
    const [line, setLine] = useState('')
    const [lineBackup, setLineBackup] = useState(null)
    const [historyCursor, setHistoryCursor] = useState(0)
    const [webSocket] = useState(new WebSocketManager(onConnected, onDisconnected, onData))
    const [currentRoomId, setCurrentRoomId] = useState()
    const inputRef = useRef(null)
    const [chatLines, setChatLines] = useState([])
    const [scrollingPaused, setScrollingPaused] = useState(false)

    const terminalContainerRef = useRef(null)

    const isAdmin = currentRoom()?.admins.find( admin => admin.id === user.id)

    useEffect(() => {
        setCurrentRoomId(rooms?.[0]?.id)
    }, [rooms])

    useEffect(() => {
        const term = terminalContainerRef.current
        if (term && Math.abs(term.scrollHeight - term.clientHeight - term.scrollTop) >= 1 && !scrollingPaused) {
            setTimeout(() => term.scrollTo({ left: 0, top: term.scrollHeight, behavior: 'smooth' }), 1)
        }
    }, [chatLines])

    useEffect(() => {
        dispatch(Actions.setConnectedRoom(connected ? currentRoomId : null))
    }, [connected])

    // An easy way for other components manipulate the room queue on the server
    global.chatRoom = {
        playMode,
        getCurrentQueue,
        queueTrack,
        removeTrack,
        trackEnded
    }

    function addChatLine(message) {
        setChatLines(oldLines => [ ...oldLines, <div key={oldLines.length} className="ChatLine">{ message }</div> ])
    }

    function addHistory() {
        const newHistory = [ line, ...commandHistory ]
        if (newHistory.length > 100)
            newHistory.pop()
        setCommandHistory(newHistory)
    }

    function currentRoom() {
        return rooms?.find( r => r.id === currentRoomId )
    }

    function getCurrentQueue() {
        webSocket.sendMessage({ op: 'currentQueue' })
    }

    function handleAnnounce(data) {
        const { text } = data
        addChatLine(text)
    }

    function handleChat(data) {
        const { user: { username }, text } = data
        let message = ''
        if (text[0] === ':') {
            message = `${username} ${text.substring(1)}`
        } else {
            const verb = text.slice(-1) === '?' ? 'asks' : 'says'
            message = `${username} ${verb}, "${text}"`
        }
        addChatLine(message)
    }

    function handleConnect(data) {
        const { user: { username } } = data
        addChatLine(`${username} connected.`)
    }

    function handleDisconnect(data) {
        const { user: { username } } = data
        addChatLine(`${username} disconnected.`)
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            addHistory()
            processInput(line)
            setLine('')
        } else if (e.key === 'ArrowUp') {
            if (commandHistory.length === 0) {
                // Do nothing
            } else if (historyCursor === 0 && lineBackup == null) {
                setLineBackup(line)
                setLine(commandHistory[0])
                const end = commandHistory[0].length
                setTimeout(() => inputRef.current.setSelectionRange(end, end), 0)
            } else {
                const newCursor = Math.min(commandHistory.length - 1, historyCursor + 1)
                setHistoryCursor(newCursor)
                setLine(commandHistory[newCursor])
                const end = commandHistory[newCursor].length
                setTimeout(() => inputRef.current.setSelectionRange(end, end), 0)
            }
        } else if (e.key === 'ArrowDown') {
            if (historyCursor === 0 && lineBackup != null) {
                setLine(lineBackup)
                setLineBackup(null)
            } else if (historyCursor > 0) {
                const newCursor = historyCursor - 1
                setHistoryCursor(newCursor)
                setLine(commandHistory[newCursor])
                const end = commandHistory[newCursor].length
                inputRef.current.setSelectionRange(end, end)
            }
        }
    }

    function handleNowPlaying(data) {
        const { track } = data
        addChatLine(<span>Now playing { trackLink(track) } by <b>{ track.artist }</b>.</span>)
    }

    function handleQueue(data) {
        if (global.chatRoom.playMode !== 'room') return
        const { queue } = data
        dispatch(Actions.setQueue(queue))
    }

    function handleQueueUpdated(data) {
        const { user: { username }, operation, queue, track } = data
        addChatLine(<span>{username} {operation} { trackLink(track) } by <b>{track.artist}</b> { operation === 'added' ? 'to' : 'from' } the play queue.</span>)
        handleQueue({ queue })
    }

    const handleScroll = (e) => {
        const {scrollTop, scrollHeight, clientHeight} = e.target
        const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100)
        if (position < 100 && !scrollingPaused) setScrollingPaused(true)
        else if (scrollingPaused) setScrollingPaused(false)
    }

    function handleUsers(data) {
        addChatLine('User                 Idle  Connected')
        addChatLine('------------------------------------')
        const sortedUsers = data.users.sort( (a, b) => (a.updated > b.updated ? 1 : -1) )
        sortedUsers.forEach( user => addChatLine(`${left(user.username, 20)} ${right(idleTime(user), 5)} ${left(new Date(user.connected).toLocaleString())}`))
    }

    function onConnected() {
        addChatLine('*** Connected ***')
        setConnected(true)
        dispatch(Actions.setPlayMode('room'))
        getCurrentQueue()
    }

    function onData(data) {
        // WARNING: The scope of these functions is captured by WebSocket at the time of its creation, so it's not
        // safe to read state variables in any of them.  They should be write-only with regard to the state of this
        // element.
        switch (data.type) {
            case 'announce':
                handleAnnounce(data)
                break
            case 'chat':
                handleChat(data)
                break
            case 'connected':
                handleConnect(data)
                break
            case 'disconnected':
                handleDisconnect(data)
                break
            case 'nowPlaying':
                handleNowPlaying(data)
                break
            case 'queue':
                handleQueue(data)
                break
            case 'queuedUpdated':
                handleQueueUpdated(data)
                break
            case 'users':
                handleUsers(data)
                break
            default:
                console.log(`unhandled websocket data: ${JSON.stringify(data)}`)
        }
    }

    function onDisconnected() {
        addChatLine('*** Disconnected ***')
        setConnected(false)
        dispatch(Actions.setPlayMode('local'))
    }

    function onRoomSelected(e) {
        const newRoom = e.target.value
        console.log(`room selected => ${JSON.stringify(newRoom)}`)
        if (newRoom !== currentRoomId) {
            setCurrentRoomId(newRoom)
            webSocket.close()
        }
    }

    function parseCommand(input) {
        const parts = input.slice(1).split(/\s+/)
        const cmd = parts[0]
        switch (cmd) {
            case 'help':
                chatHelp.forEach( ln => addChatLine(ln) )
                break
            case 'who':
                webSocket.sendMessage({ op: 'who' })
                break
            default:
                addChatLine(`Unknown command "${cmd}".  Type /help for a list of valid commands.`)
        }
    }

    function processInput(input) {
        if (input.startsWith('/')) {
            parseCommand(input)
        } else {
            webSocket.sendMessage({ op: 'chat', text: input })
        }
    }

    function queueTrack(track) {
        webSocket.sendMessage({ op: 'queueTrack', hash: track.hash })
    }

    function removeTrack(entry) {
        webSocket.sendMessage({ op: 'removeTrack', entry })
    }

    function showAddMusic() {
        dispatch(Actions.showSheet({ action: 'showAddMusic', data: currentRoomId }))
    }

    function showRoomDetails() {
        dispatch(Actions.showSheet({ action: 'showRoomDetails', data: currentRoom() }))
    }

    async function toggleConnect() {
        console.log(`room => ${currentRoomId} connected => ${connected}`)
        if (currentRoomId && !connected) {
            webSocket.connect(currentRoomId)
        } else {
            webSocket.close()
        }
    }

    function trackEnded(track) {
        webSocket.sendMessage({ op: 'trackEnded', track })
    }

    function trackLink(track) {
        return <span className="TrackLink" onClick={() => dispatch(Action.showSheet({ action: 'showTrack', data: track }))}>{ track.title }</span>
    }

    function updateInput(e) {
        const newText = e.target.value.replace('\n', '')
        setLine(newText)
    }

    const room = currentRoom()
    let emptyContent
    if (!user?.username) {
        return (
            <div className="ChatPage">
                <div className="ChatEmpty">
                    <p>You haven't set your username.  You need a username to chat.</p>
                    <p>You can set your username on the <span onClick={() => dispatch(Actions.navigateTo('profile'))} className="Link">profile screen</span>.</p>
                </div>
            </div>
        )
    } else if (!room) {
        emptyContent = (
            <div className="ChatEmpty">
                <p>You are not a member of any Tunistry listening rooms.</p>
                <p>You can join one by accepting an invite from another user, or <span onClick={() => dispatch(Actions.showSheet({ action: 'showCreateRoom' }))} className="Link">create one of your own</span>.</p>
            </div>
        )
    } else if (room.members.length < 2) {
        emptyContent = (
            <div className="ChatEmpty">
                <p>You are the only member of this listening room.</p>
                <p><span onClick={() => dispatch(Actions.showSheet({ action: 'showRoomDetails', data: currentRoom() }))} className="Link">Invite your friends</span> to join you and listen together.</p>
            </div>
        )
    }

    return (
        <div className="ChatPage">
            { room ? [
                <div key={1} className="ChatControls">
                    { isAdmin ? [
                        <div key={1} className="RoomManage">
                            <button className="RoomManageButton" onClick={showRoomDetails}>Manage Room</button>
                        </div>,
                        <div key={2} className="RoomAddMedia">
                            <button className="RoomAddMediaButton" onClick={showAddMusic}>Add Music</button>
                        </div> ]
                        : null }
                    <div className="RoomSelector">
                        <select disabled={connected} value={currentRoomId} onChange={onRoomSelected}>{ rooms.map( r => <option key={r.id} value={r.id}>{r.name}</option> ) }</select>
                        <button onClick={toggleConnect}>{ connected ? 'Disconnect' :  'Connect' }</button>
                    </div>
                </div>,
                <div key={2} className="TerminalContainer" ref={terminalContainerRef} onScroll={handleScroll}>
                    <ChatContainer>
                        { chatLines }
                    </ChatContainer>
                </div>,
                <div key={3} className="TerminalInputContainer">
                    <textarea ref={inputRef} rows={3} className="TerminalInput" value={line} onChange={updateInput} onKeyDown={handleKeyPress} />
                </div>
            ] : null }
            { emptyContent }
        </div>
    )
}
