
export default function Tag(props) {
    const removeButton = props.editing ? (
        <div className="TagEditingRemoveButton" onClick={() => props.onRemove(props.tag)}>
            <span className="material-symbols-outlined Button">cancel</span>
        </div>
    ) : null

    return (
        <div className={`Tag ${props.editing ? 'TagEditing' : ''}`}>
            { props.tag }
            { removeButton }
        </div>
    )
}
