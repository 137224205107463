import Queue from './Queue'
import { useAppContext } from '../AppContext'

export default function Listen() {

    const appState = useAppContext()
    const { playMode, rooms, connectedRoom } = appState
    const playingFrom = playMode === 'local' ? undefined : <div className="QueueInfo">Playing from: { currentRoom()?.name }</div>

    function currentRoom() {
        return rooms?.find( room => room.id === connectedRoom )
    }


    return (
        <div className="ListenPage">
            { playingFrom }
            <Queue />
        </div>
    )
}
