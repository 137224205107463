import { Actions } from '../AppReducer'
import { useAppContext, useAppDispatch } from '../AppContext'

export default function useQueueTrack() {

    const dispatch = useAppDispatch()
    const { playMode } = useAppContext()

    const queueTrack = (track) => {
        switch (playMode) {
            case 'room': {
                global.chatRoom.queueTrack(track)
                dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the queue.</span>}))
                break
            }
            case 'local': {
                dispatch(Actions.queueTrack(track))
                dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the queue.</span>}))
                break
            }
            default:
                console.error(`unknown play mode: ${playMode}`)
        }
    }

    return queueTrack
}
