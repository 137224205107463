import { useEffect, useState } from 'react'
import { addMusicByHashBatch, search } from '../api/Api'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import TrackControls from '../track/TrackControls'
import Spinner from '../widgets/Spinner'

const debounceTime = 400

export default function AddMusic(props) {

    const appState = useAppContext()
    const dispatch = useAppDispatch()
    const userId = appState.user?.id

    const blankParams = { searchBy: 'artist', text: '', tags: '' }
    const [params, setParams] = useState(blankParams)
    const [cursor, setCursor] = useState()
    const [loading, setLoading] = useState(false)
    const [adding, setAdding] = useState()
    const [currentResults, setCurrentResults] = useState([])
    const [abortController, setAbortController] = useState(new AbortController())
    const [currentRequest, setCurrentRequest] = useState(0)

    useEffect(() => { performSearch() }, [params])
    useEffect(() => { setParams(blankParams) }, [userId])

    async function addAllSearchResults(e) {
        dispatch(Actions.showModal({
            action: 'confirmation',
            text: 'Are you sure you want to add all tracks that match this search? Depending on how many tracks match this could take a while.',
            confirmAction: addMusic,
            confirmText: 'Add All Matching Tracks'
        }))
    }

    async function addMusic() {
        let addCursor
        let totalAdded = 0
        let finished = false
        const { searchBy, text, tags } = params
        while (!finished) {
            const results = await search({
                pk: `user#${userId}`,
                searchBy,
                text,
                tags,
                cursor: addCursor,
                limit: 100
            }, abortController.signal)
            if (results.items?.length) {
                const hashes = results.items.map( item => item.hash )
                totalAdded += await addMusicByHashBatch(props.roomId, hashes)
            }
            addCursor = results.cursor
            if (!addCursor) finished = true
        }
        return <span>Added <b>{ totalAdded }</b> track{ totalAdded > 1 ? 's' : ''}.</span>
    }

    function handleScroll(e) {
        const {scrollTop, scrollHeight, clientHeight} = e.target
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100
        )
        if (!loading && position > 80 && cursor) {
            performSearch()
        }
    }

    function paramsUpdated(field, value) {
        const newParams = { ...params }
        newParams[field] = value
        setParams(newParams)
        setCurrentResults([])
        setCursor()
    }

    async function performSearch() {
        if (!userId) return
        const { searchBy, text, tags } = params
        setLoading(true)
        const newAbortController = new AbortController()
        setAbortController( prevController => {
            prevController.abort()
            return newAbortController
        })
        setCurrentRequest( prevRequest => {
            if (prevRequest)
                clearTimeout(prevRequest)
            setTimeout(async () => {
                    try {
                        const results = await search({
                            pk: `user#${userId}`,
                            searchBy,
                            text,
                            tags,
                            cursor,
                            limit: 100
                        }, newAbortController.signal)
                        setCurrentResults(currentResults.concat(results.items ?? []))
                        setCursor(results.cursor)
                        setLoading(false)
                    } catch (error) {}
                },
                debounceTime
            )
        })
    }

    return (
        <div className="SearchPage">
            <div className="SearchParams">
                <div className="SearchParamsGrid">
                    <div className="GridLabel" style={{ gridArea: 'searchByLabel' }}>Search</div>
                    <div className="GridText" style={{ gridArea: 'searchBy' }}>
                        <select className="SearchTypeSelect" value={params.searchBy} onChange={(e) => paramsUpdated('searchBy', e.target.value)}>
                            <option value="artist">Artist</option>
                            <option value="title">Title</option>
                            <option value="album">Album</option>
                        </select>
                        <input className="SearchTextInput" type="text" style={{ width: '200px' }} value={params.text} onChange={(e) => paramsUpdated('text', e.target.value)} />
                    </div>
                    <div className="GridLabel" style={{ gridArea: 'tagsLabel' }}>Tags</div>
                    <div className="GridText" style={{ gridArea: 'tags' }}>
                        <input type="text" style={{ width: '200px' }} value={params.tags} onChange={(e) => paramsUpdated('tags', e.target.value)} />
                    </div>
                    <div className="GridLabel" style={{ gridArea: 'buttonsLabel' }}>Add All</div>
                    <div className="GridText" style={{ gridArea: 'buttons' }}>
                        {adding === 'all' ? <Spinner/> :
                            <span className="material-symbols-outlined TrackControlButton"
                                  onClick={(e) => addAllSearchResults()}>playlist_add</span>
                        }
                    </div>
                </div>
            </div>

            <div className="SearchResults"  onScroll={handleScroll}>
                { currentResults.length > 0 ?
                    <div style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr 1fr 1fr' }}>
                        { currentResults.map( (track, index) => [
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 1}} key={`${index}a`}>
                                <TrackControls track={track} addToRoom={props.roomId} />
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 2 }} key={`${index}b`}>
                                <div>{track.artist}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 3 }} key={`${index}c`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showTrack', data: track }))}>{track.title ?? '[ untitled ]'}</div>
                            </div>,
                            <div className={ index % 2 === 0 ? 'SearchResult Even' : 'SearchResult Odd' } style={{ gridRow: index + 1, gridColumn: 4 }} key={`${index}d`}>
                                <div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showAlbum', data: track }))}>{track.album}</div>
                            </div>
                        ] ) }
                    </div>
                :
                    <div className="SearchNoResults">
                        <p>No matches for this search.</p>
                        <p>To add music you can <span className="Link" onClick={() => dispatch(Actions.navigateTo('upload'))}>upload your music files</span> or purchase new digital music from one of our affiliates.</p>
                    </div>
                }
                { loading ? <Spinner /> : null }
            </div>
        </div>
    )
}
