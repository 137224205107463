import { AppContext, AppDispatchContext } from './AppContext.js'
import { useAppReducer } from './AppReducer'
import Login from './login/Login'
import Main from './Main'
import './App.css'

function App() {

    const [appState, dispatch] = useAppReducer()

    return (
        <div className="App">
            <AppContext.Provider value={appState}>
                <AppDispatchContext.Provider value={dispatch}>
                    <div>
                        { appState.loggedIn ? <Main /> : <Login /> }
                    </div>
                </AppDispatchContext.Provider>
            </AppContext.Provider>
        </div>
    )
}

export default App
