import { useEffect, useRef, useState } from 'react'

export default function EditText(props) {
    const { editEnabled, onClick, onBlur, text, updateText, className = '', error } = props
    const [editing, setEditing] = useState(editEnabled)
    const editorRef = useRef(null)
    useEffect(() => {
        if (editing) {
            editorRef.current?.focus()
        }
    }, [editing])

    function gainFocus() {
        setEditing(true)
        onClick?.()
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' || e.key === 'Escape') loseFocus()
    }

    function loseFocus() {
        setEditing(false)
        onBlur?.()
    }

    let textElement
    if (editEnabled && editing) {
        textElement = <input
            type="text"
            value={text}
            ref={editorRef}
            className={`EditTextInput ${className}`}
            onChange={updateText}
            onKeyDown={handleKeyPress}
            onBlur={loseFocus}
        />
    } else {
        textElement = <span className={`EditTextText ${className}`}>{ text }</span>
    }

    return (
        <div className="EditText" onClick={gainFocus}>
            { textElement }
            { editing && error ? <div className="EditTextError">{ error }</div> : null }
        </div>
    )
}
