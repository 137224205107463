import { useReducer } from 'react'
import { validTokenExists } from './api/Api'
export const useAppReducer = () => useReducer(appReducer, initialAppState)

function appReducer(appState, action) {
    // console.log(`action => ${JSON.stringify(action, null, 4)}`)
    switch (action.name) {
        case 'advanceQueue':
            return { ...appState, queue: appState.queue.slice(1) }
        case 'dismissMainModal':
            return { ...appState, mainModalOpen: false, mainModalState: null }
        case 'dismissMainSheet':
            return { ...appState, mainSheetOpen: false, mainSheetState: null }
        case 'dismissToast':
            return { ...appState, showToast: false }
        case 'logIn':
            return { ...appState, loggedIn: true }
        case 'logOut':
            return { ...appState, loggedIn: false }
        case 'navigateTo':
            return { ...appState, activeTab: action.value }
        case 'playTrack':
            return { ...appState, queue: [ { track: action.value } ], playMode: 'local' }
        case 'queueTrack':
            return { ...appState, queue: [ ...appState.queue, { track: action.value } ] }
        case 'removeFromQueue':
            return { ...appState, queue: appState.queue.filter((item, index) => index !== action.value) }
        case 'removeTracks':
            return { ...appState, removeTracks: action.value }
        case 'setConnectedRoom':
            return { ...appState, connectedRoom: action.value }
        case 'setPlayMode':
            return { ...appState, playMode: action.value }
        case 'setQueue':
            return { ...appState, queue: action.value }
        case 'setRooms':
            return { ...appState, rooms: action.value }
        case 'setUser':
            return { ...appState, user: action.value }
        case 'showModal':
            return { ...appState, mainModalState: action.value, mainModalOpen: true }
        case 'showSheet':
            return { ...appState, mainSheetState: action.value, mainSheetOpen: true }
        case 'showToast':
            return { ...appState, toastState: action.value, showToast: true }
        default:
            throw new Error(`Unexpected state in action: ${JSON.stringify(action)}`)
    }
}

export const Actions = {
    advanceQueue: () => ({
        name: 'advanceQueue'
    }),
    dismissMainModal: () => ({
        name: 'dismissMainModal'
    }),
    dismissMainSheet: () => ({
       name: 'dismissMainSheet'
    }),
    dismissToast: () => ({
        name: 'dismissToast'
    }),
    logIn: () => ({
        name: 'logIn'
    }),
    logOut: () => ({
        name: 'logOut'
    }),
    navigateTo: (dest) => ({
        name: 'navigateTo',
        value: dest
    }),
    playTrack: (track) => ({
        name: 'playTrack',
        value: track
    }),
    queueTrack: (track) => ({
       name: 'queueTrack',
       value: track
    }),
    removeFromQueue: (index) => ({
        name: 'removeFromQueue',
        value: index
    }),
    removeTracks: (tracks) => ({
        name: 'removeTracks',
        value: tracks
    }),
    setConnectedRoom: (room) => ({
        name: 'setConnectedRoom',
        value: room
    }),
    setPlayMode: (mode) => ({
        name: 'setPlayMode',
        value: mode
    }),
    setQueue: (queue) => ({
        name: 'setQueue',
        value: queue
    }),
    setRooms: (rooms) => ({
        name: 'setRooms',
        value: rooms
    }),
    setUser: (user) => ({
        name: 'setUser',
        value: user
    }),
    showSheet: (data) => ({
       name: 'showSheet',
       value: data
    }),
    showModal: (data) => ({
       name: 'showModal',
       value: data
    }),
    showToast: (data) => {
        if (data.timeout !== -1)
            setTimeout(() => global.uiDispatch(Actions.dismissToast()), data.timeout ?? 3000)
        return {
            name: 'showToast',
                value: data
        }
    }
}

const initialAppState = {
    activeTab: 'search',
    connectedRoom: null,
    loggedIn: validTokenExists(),
    mainModalOpen: false,
    mainModalState: null,
    mainSheetOpen: false,
    mainSheetState: null,
    playMode: 'local',
    queue: [],
    removeTracks: [],
    rooms: [],
    showToast: false,
    toastState: null,
    user: null
}
