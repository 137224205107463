import { useState } from 'react'
import { loginWithEmail } from '../api/Api'
import { emailIsValid } from '../util/validation'
import Spinner from '../widgets/Spinner'

export default function EmailLoginModal(props) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showingSpinner, showSpinner] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [abortController] = useState(new AbortController())

    function cancel(e) {
        abortController.abort()
        showSpinner(false)
        props.show('') // close
    }

    function createAccount(e) {
        if (showingSpinner) {
            cancel(e)
        }
        props.show('emailCreateAccount')
    }

    function emailUpdated(e) {
        setErrorMessage('')
        setEmail(e.target.value)
    }

    async function logIn(e) {
        const emailProblem = validateEmail()
        if (emailProblem) {
            setErrorMessage(emailProblem)
            return
        }
        const passwordProblem = validatePassword()
        if (passwordProblem) {
            setErrorMessage(passwordProblem)
            return
        }
        showSpinner(true)
        try {
            const response = await loginWithEmail(email, password, abortController.signal)
            showSpinner(false)
            if (response.ok) {
                props.loggedIn()
            } else {
                if (response.status === 403) {
                    props.show('verifyEmail')
                } else {
                    const err = await response.json()
                    setErrorMessage(err.error)
                }
            }
        } catch (err) {
            showSpinner(false)
            setErrorMessage(err.message)
        }
    }

    function passwordUpdated(e) {
        setErrorMessage('')
        setPassword(e.target.value)
    }

    function validateEmail() {
        if (!emailIsValid(email)) return 'Invalid email address.'
        return null
    }

    function validatePassword() {
        if (password.length < 1) return 'You must supply a password to log in.'
        return null
    }

    return (
        <div className="Dimmer">
            <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp">
                <div style={{ display: 'grid', gridTemplateColumns: '100px 1fr', gridColumnGap: '5px', gridRowGap: '3px' }}>
                    <div style={{ gridRow: 1, gridColumn: 1, textAlign: 'right' }}>Email:</div>
                    <div style={{ gridRow: 1, gridColumn: 2, textAlign: 'left' }}>
                        <input type="text" style={{ width: '100%' }} value={email} onChange={emailUpdated}/>
                    </div>
                    <div style={{ gridRow: 2, gridColumn: 1, textAlign: 'right' }}>Password:</div>
                    <div style={{ gridRow: 2, gridColumn: 2, textAlign: 'left' }}>
                        <input type="password" style={{ width: '100%' }} value={password} onChange={passwordUpdated}/>
                    </div>
                    <div style={{ gridRow: 3, gridColumn: 1, textAlign: 'right' }}>
                        <div style={{ height: '25px', marginTop: '8px', marginRight: '10px', marginBottom: '0px' }}>
                            { showingSpinner ? <Spinner /> : null }
                        </div>
                    </div>
                    <div style={{ gridRow: 3, gridColumn: 2, marginTop: '10px', textAlign: 'left' }}>
                        <button style={{ marginRight: '10px' }} onClick={cancel}>Cancel</button>
                        <button style={{ marginRight: '10px' }} disabled={showingSpinner} onClick={logIn}>Log In</button>
                    </div>
                    <div style={{ gridRow: 4, gridColumn: 2, textAlign: 'right' }}>
                        <p className="Link" style={{ fontSize: '12px' }} onClick={createAccount}>Need an account?</p>
                    </div>
                    { errorMessage ?
                        <div style={{ gridRow: 6, gridColumnStart: 1, gridColumnEnd: 3, textAlign: 'center' }}>
                            <p className="ErrorMessage">{ errorMessage }</p>
                        </div>
                        : null }
                </div>
            </div>
            </div>
        </div>
    )
}