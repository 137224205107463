import { useEffect, useState } from 'react'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import {
    addRoomAdmin,
    emailInvite,
    getRoomData,
    removeRoomAdmin,
    removeRoomUser,
    updateRoom
} from '../api/Api'
import Spinner from '../widgets/Spinner'
import EditText from '../widgets/EditText'
import PeopleView from './PeopleView'
import { emailIsValid } from '../util/validation'

export default function RoomDetails(props) {

    const dispatch = useAppDispatch()
    const appState = useAppContext()
    const { user } = appState
    const { admins, members } = props.room
    const owner = admins.find( admin => admin.id === props.room.owner )
    const userIsOwner = owner.id === user.id
    const userIsAdmin = admins.find( admin => admin.id === user.id )

    const [loading, setLoading] = useState()
    const [loadingIndex, setLoadingIndex] = useState(-1)
    const [name, setName] = useState()
    const [topic, setTopic] = useState()
    const [spinning, setSpinning] = useState()
    const [editing, setEditing] = useState()
    const [inviteEmail, setInviteEmail] = useState('')
    const [inviteMessage, setInviteMessage] = useState('')
    const [inviteError, setInviteError] = useState('')
    const [addAdminUsername, setAddAdminUsername] = useState('')

    useEffect(() => {
        setName(props.room.name)
        setTopic(props.room.topic)
        setSpinning(props.room.spinning)
    }, [props, props.room.members, props.room.admins])

    const spinner = loading ? <Spinner /> : null

    async function addAdmin() {
        if (props.room.admins.find( a => a.username === addAdminUsername )) {
            dispatch(Actions.showModal({ action: 'alert', text: 'That user is already an admin.' }))
            return
        }
        const member = props.room.members.find( m => m.username === addAdminUsername )
        if (member) {
            setLoadingIndex(1000)
            const added = await addRoomAdmin(props.room.id, member.id)
            if (added) {
                props.room.admins = [ ...props.room.admins, member ]
            }
            setLoadingIndex(-1)
        } else {
            dispatch(Actions.showModal({ action: 'alert', text: 'There is no room member with that username.' }))
        }
    }

    function removeAdmin(person) {
        if (user.id !== owner.id) return
        if (person.id === owner.id) {
            dispatch(Actions.showModal({
                action: 'alert',
                text: 'You are the owner of this room.  Owners always have admin privileges.'
            }))
        } else {
            dispatch(Actions.showModal({
                action: 'confirmation',
                text: `Are you sure you want to remove admin privileges for ${person.username}?  You will lose access to all of the music they have contributed to this room.`,
                confirmText: 'Remove Admin and their music',
                confirmAction: async () => {
                    const removed = await removeRoomAdmin(props.room.id, person.id)
                    if (removed) {
                        props.room.admins = props.room.admins.filter( admin => admin.id !== person.id )
                    }
                }
            }))
        }
    }

    function removeMember(person) {
        console.log(`remove member => ${JSON.stringify(person)}`)
        if (admins.find( admin => admin.id === person.id )) {
            console.log(`can't remove admin`)
            const modalParams = {
                action: 'alert',
                text: 'This person is an admin.  In order to remove them the owner of the room will need to revoke their admin status.',
                okText: 'OK'
            }
            dispatch(Actions.showModal(modalParams))
        } else {
            const modalParams = {
                action: 'confirmation',
                text: `Are you sure you want to remove ${person.username}?`,
                confirmText: 'Remove Member',
                confirmAction: async () => {
                    if (await removeRoomUser(props.room.id, person.id)) {
                        await getRoomData()
                        props.room.members = props.room.members.filter( member => member.id !== person.id )
                    }
                    dispatch(Actions.dismissMainModal())
                }
            }
            dispatch(Actions.showModal(modalParams))
        }
    }

    async function resendEmailInvite(index) {
        setLoadingIndex(index)
        setInviteMessage('')
        setInviteError('')
        const email = props.room.invites[index].email
        try {
            const res = await emailInvite(email, props.room.id)
            if (res.ok) {
                setInviteMessage('Invitation re-sent!')
            } else {
                const body = await res.json()
                setInviteError(body.error)
            }
            setLoadingIndex(-1)
        } catch (error) {
            setInviteError(error.message)
            setLoadingIndex(-1)
        }
    }

    async function saveField(field, value) {
        if (props.room[field] !== value) {
            await updateRoom(props.room.id, {[field]: value})
            props.room[field] = value
        }
    }

    async function sendEmailInvite() {
        setInviteMessage('')
        setInviteError('')
        setLoading(true)
        try {
            const res = await emailInvite(inviteEmail, props.room.id)
            if (res.ok) {
                setInviteMessage('Invitation sent!')
            } else {
                const body = await res.json()
                setInviteError(body.error)
            }
            setLoading(false)
        } catch (error) {
            setInviteError(error.message)
            setLoading(false)
        }
    }

    function tryToEdit(field) {
        if (field === 'admins' && userIsOwner) {
            setEditing('admins')
        } else if (field === 'addAdmin' && userIsOwner) {
            setEditing('addAdmin')
        } else if (field !== 'admins' && userIsAdmin) {
            setEditing(field)
        }
    }

    function updateSpinning(text) {
        const newSpinning = text.replaceAll(/[^a-zA-Z0-9_ -]/g, '')
        setSpinning(newSpinning)
    }

    let invitationsSection
    if (userIsAdmin) {
        const invitations = props.room.invitations?.map( (invite, index) => [
            <div key={`ea-${index}`} style={{ gridRow: index, gridColumn: 1 }}>{ invite.email }</div>,
            <div key={`rb-${index}`} style={{ gridRow: index, gridColumn: 2 }}>
                <button disabled={loading || loadingIndex > -1} onClick={() => resendEmailInvite(index)}>Resend</button>
                { loadingIndex === index ? <Spinner /> : null }
            </div>
        ])
        invitationsSection = [
            <div key={1} className="GridLabel" style={{ gridArea: 'invitesLabel' }}>Invitations</div>,
            <div key={2} className="GridText" style={{ gridArea: 'invites' }}>
                <div className="RoomInvitations">
                    <div className="ActiveInvites">
                        <div className="InvitationsActiveGrid">
                            { invitations }
                        </div>
                    </div>
                    <div className="InviteByEmail">
                        <input value={inviteEmail} placeholder="Email address" onChange={(e) => setInviteEmail(e.target.value)} />
                        <button className="InviteByEmailButton" disabled={loading || loadingIndex > -1 || !emailIsValid(inviteEmail)} onClick={sendEmailInvite}>Send Invite</button>
                        { spinner }
                    </div>
                    <div className="InviteMessage">{ inviteMessage }</div>
                    <div className="InviteError">{ inviteError }</div>
                </div>
            </div>
        ]
    }

    let addAdminSection
    if (userIsOwner) {
        addAdminSection = (
            <div className="RoomAddAdmin">
                <input value={addAdminUsername} placeholder="Username" onClick={() => tryToEdit('addAdmin')} onChange={(e) => setAddAdminUsername(e.target.value)} />
                <button className="AddAdminButton" disabled={loading || loadingIndex > -1} onClick={addAdmin}>Add Admin</button>
                { spinner }
            </div>
        )
    }

    return (
        <div>
            <div className="RoomDetailsGrid">
                <div className="GridLabel" style={{ gridArea: "nameLabel" }}>Name</div>
                <div className="GridText" style={{ gridArea: "name" }}>
                    <EditText
                        editEnabled={editing === 'name'}
                        text={name}
                        onBlur={() => saveField('name', name)}
                        updateText={(e) => setName(e.target.value)}
                        onClick={() => tryToEdit('name')}
                    />
                </div>
                <div className="GridLabel" style={{ gridArea: "topicLabel" }}>Topic</div>
                <div className="GridText" style={{ gridArea: "topic" }}>
                    <EditText
                        editEnabled={editing === 'topic'}
                        text={topic}
                        onBlur={() => saveField('topic', topic)}
                        updateText={(e) => setTopic(e.target.value)}
                        onClick={() => tryToEdit('topic')}
                    />
                </div>
                <div className="GridLabel" style={{ gridArea: "spinningLabel" }}>Spinning</div>
                <div className="GridText" style={{ gridArea: "spinning" }}>
                    <EditText
                        editEnabled={editing === 'spinning'}
                        text={spinning}
                        onBlur={() => saveField('spinning', spinning)}
                        updateText={(e) => updateSpinning(e.target.value)}
                        onClick={() => tryToEdit('spinning')}
                    />
                </div>
                <div className="GridLabel" style={{ gridArea: "ownerLabel" }}>Owner</div>
                <div className="GridText" style={{ gridArea: "owner" }}>{ owner.username }</div>
                <div className="GridLabel" style={{ gridArea: "adminsLabel" }}>Admins</div>
                <div className="GridText" style={{ gridArea: "admins"}}>
                    <div>
                    <PeopleView
                        people={admins}
                        editing={editing === 'admins'}
                        onClick={() => tryToEdit('admins')}
                        onRemovePerson={removeAdmin}
                    />
                    { addAdminSection }
                    </div>
                </div>
                <div className="GridLabel" style={{ gridArea: 'membersLabel' }}>Members</div>
                <div className="GridText" style={{ gridArea: 'members' }}>
                    <PeopleView
                        people={members}
                        editing={editing === 'members'}
                        onClick={() => tryToEdit('members')}
                        onRemovePerson={removeMember}
                    />
                </div>
                { invitationsSection }
            </div>
        </div>
    )
}
