import { jwtDecode } from 'jwt-decode'
import { Actions } from '../AppReducer'

const baseUrl = process.env.REACT_APP_API_URL

export async function abortUpload(uploadId, hash, signal) {
    const headers = await getHeaders(signal)
    return fetch(`${baseUrl}/upload/multipart/abort`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ hash, uploadId }),
        signal
    })
}

export async function acceptInvite(roomId, email, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/invite/accept`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ roomId, email })
    })
    return res.json() // we expect the user's updated invite list
}

export async function addRoomAdmin(roomId, userId, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/${roomId}/admin/${userId}`, {
        method: 'POST',
        headers
    })
    return res.json()
}

export async function addMusicByHash(roomId, hash, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/${roomId}/music/${hash}`, {
        method: 'PUT',
        headers
    })
    return res.json()
}

export async function addMusicByHashBatch(roomId, hashes, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/${roomId}/music`, {
        method: 'POST',
        body: JSON.stringify({ hashes }),
        headers
    })
    if (res.ok)
        return res.json()
    else
        throw Error(`Adding music failed: ${JSON.stringify(await res.json())}`)
}

export async function albumInfo(pk, artist, album, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/album/info`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ pk, artist, album }),
        signal
    })
    return res.json()
}

export async function checkUpload(hash, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/check/${hash}`, {
        method: 'GET',
        headers,
        signal
    })
    return res.json()
}

export async function completeUpload(uploadId, hash, parts, signal) {
    const headers = await getHeaders(signal)
    return fetch(`${baseUrl}/upload/multipart/finish`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ hash, uploadId, parts }),
        signal
    })
}

export async function createAccountWithEmail(email, password, signal) {
    setMostRecentEmailAddress(email)
    const headers = await getHeaders(signal)
    return fetch(`${baseUrl}/user`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ email, password }),
        signal
    })
}

export async function declineInvite(roomId, email, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/invite/decline`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ roomId, email })
    })
    return res.json() // we expect the updated invites list
}

export async function emailInvite(email, roomId, signal) {
    const headers = await getHeaders(signal)
    return fetch(`${baseUrl}/room/invite`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ email, roomId }),
        signal
    })
}

function getAccessToken() {
    return localStorage.getItem('at')
}

export async function getOrFetchAccessToken(signal) {
    const token = getAccessToken()
    if (!token || tokenIsExpired(token)) {
        // Check for a valid refresh token
        const refreshToken = getRefreshToken()
        if (refreshToken && !tokenIsExpired(refreshToken)) {
            console.log(`API: refreshing access token`)
            return await refreshAccessToken(refreshToken, signal)
        }
    }
    return token
}

async function getHeaders(signal) {
    const headers = {
        'Content-Type': 'application/json'
    }
    const token = await getOrFetchAccessToken(signal)
    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    } else {
        console.log('API: refreshing token failed, logging out')
        logout()
    }
    return headers
}

export async function getInvites(signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/invite`, {
        method: 'GET',
        headers,
        signal
    })
    return res.json()
}

export function getMostRecentEmailAddress() {
    return localStorage.getItem('email')
}

function getRefreshToken() {
    return localStorage.getItem('rt')
}

export async function getUploadParts(hash, uploadId, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/multipart/list`, {
        method: 'GET',
        headers,
        body: JSON.stringify({ uploadId, hash }),
        signal
    })
    return res.json()
}

export async function getUserData(signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/user`, {
        method: 'GET',
        headers,
        signal
    })
    if (res.ok) {
        const user = await res.json()
        global.uiDispatch(Actions.setUser(user))
        return user
    } else {
        return null
    }
}

export async function getRoomData(signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room`, {
        method: 'GET',
        headers,
        signal
    })
    if (res.ok) {
        const rooms = await res.json()
        global.uiDispatch(Actions.setRooms(rooms))
        return rooms
    } else {
        return null
    }
}

export async function loginWithEmail(email, password, signal) {
    setMostRecentEmailAddress(email)
    const headers = await getHeaders(signal)
    const response = await fetch(`${baseUrl}/user/login`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ email, password }),
        signal
    })
    if (response.ok) {
        const tokens = await response.json()
        setAccessToken(tokens.accessToken)
        setRefreshToken(tokens.refreshToken)
        await Promise.allSettled([ getUserData(signal), getRoomData(signal) ])
    }
    return response
}

export function logout() {
    setAccessToken('')
    setRefreshToken('')
    global.uiDispatch(Actions.setRooms([]))
    global.uiDispatch(Actions.setUser(null))
    global.uiDispatch(Actions.logOut())
}

export async function makeRoom(name, topic, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ name, topic }),
        signal
    })
    return res.json()
}

async function refreshAccessToken(refreshToken, signal) {
    const headers = { 'content-type': 'application/json' }
    const response = await fetch(`${baseUrl}/user/refreshToken`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ refreshToken }),
        signal
    })
    if (response.ok) {
        const { accessToken } = await response.json()
        setAccessToken(accessToken)
        return accessToken
    }
    return null
}

export async function removeTrackFromRoom(roomId, hash, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/${roomId}/music/${hash}`, {
        method: 'DELETE',
        headers
    })
    return res.json()
}

export async function removeRoomAdmin(roomId, userId, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/${roomId}/admin/${userId}`, {
        method: 'DELETE',
        headers
    })
    return res.json()
}

export async function removeRoomUser(roomId, userId, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/${roomId}/${userId}`, {
        method: 'DELETE',
        headers,
        signal
    })
    return res.ok
}

export async function removeUpload(hash, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/${hash}`, {
        method: 'DELETE',
        headers,
        signal
    })
    return res.ok
}

export async function resendVerificationEmail(email, signal) {
    const headers = await getHeaders(signal)
    return fetch(`${baseUrl}/user/resendVerification`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ email }),
        signal
    })
}

export async function roomInfo(roomId, signal) {
    const headers = await getHeaders(signal)
    const res = fetch(`${baseUrl}/room/${roomId}`, {
        method: 'GET',
        headers,
        signal
    })
    return res.json()
}

export async function search(params, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/search`, {
        method: 'POST',
        headers,
        body: JSON.stringify(params),
        signal
    })
    return res.json()
}

function setAccessToken(token) {
    localStorage.setItem('at', token)
}

function setMostRecentEmailAddress(email) {
    localStorage.setItem('email', email)
}

function setRefreshToken(token) {
    localStorage.setItem('rt', token)
}

/**
 * Gets a signed URL for an individual part of a multi-part upload.
 * @param uploadId
 * @param hash
 * @param partNumber
 * @param signal
 * @returns {Promise<void>}
 */
export async function signUploadPart(hash, uploadId, partNumber, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/multipart/sign`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ uploadId, hash, partNumber }),
        signal
    })
    return res.json()
}

/**
 * Starts a multipart upload for the file with the given hash.
 * @param hash
 * @param signal
 * @returns {Promise<any>}
 */
export async function startMultipartUpload(hash, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/multipart/start`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ hash }),
        signal
    })
    return res.json()
}

/**
 * Gets a signed URL for a single-part upload of the file with the given hash.
 * @param hash
 * @param signal
 * @returns {Promise<any>}
 */
export async function startUpload(hash, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/start`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ hash }),
        signal
    })
    return res.json()
}

function tokenIsExpired(token) {
    try {
        const claims = jwtDecode(token)
        return Date.now() / 1000 > claims.exp
    } catch (err) {
        console.log('error decoding token')
        return true
    }
}

export async function updateUpload(pk, hash, updates, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/upload/update`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ pk, hash, updates }),
        signal
    })
    return res.json()
}

export async function updateRoom(id, updates, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/room/${id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(updates),
        signal
    })
    return res.json()
}

export async function updateUsername(username, signal) {
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/user/username`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username }),
        signal
    })
    const user = await res.json()
    global.uiDispatch(Actions.setUser(user))
    return user
}

export async function usernameAvailable(username, signal) {
    if (!username) return false
    const headers = await getHeaders(signal)
    const res = await fetch(`${baseUrl}/user/usernameAvailable/${encodeURIComponent(username)}`, {
        method: 'GET',
        headers,
        signal
    })
    if (res.ok)
        return res.json()
    else
        return false
}

export function validTokenExists() {
    return !tokenIsExpired(getAccessToken()) || !tokenIsExpired(getRefreshToken())
}
