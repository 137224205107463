import { useEffect, useState } from 'react'
import { useAppContext } from '../AppContext'
import { usernameAvailable, updateUsername, logout } from '../api/Api'
import EditText from '../widgets/EditText'
import Rooms from './Rooms'

export default function Profile() {

    const appState = useAppContext()
    const originalUsername = appState.user?.username
    const email = appState.user?.email
    const [editing, setEditing] = useState('')
    const [username, setUsername] = useState(originalUsername ?? '')
    const [usernameError, setUsernameError] = useState('')

    useEffect(() => setUsername(originalUsername ?? ''), [originalUsername])
    function changeFocus(field) {
        setEditing(field)
    }

    async function checkUsername(name) {
        const available = await usernameAvailable(name)
        if (!available) {
            setUsernameError('That user name is not available.')
            return false
        } else {
            setUsernameError('')
            return true
        }
    }

    async function saveUsername() {
        if (!username || username === originalUsername) return
        const available = await checkUsername(username)
        if (available) {
            updateUsername(username)
        } else {
            setUsername(appState.user?.username ?? '')
            setUsernameError('')
        }
    }

    function usernameChanged(e) {
        const name = e.target.value
        checkUsername(name)
        setUsername(name)
    }

    return (
        <div className="ProfilePage">
            <div className="ProfileGrid">
                <div className="GridLabel" style={{ gridArea: 'emailLabel' }} onClick={() => changeFocus('')}>Email</div>
                <div className="GridText" style={{ gridArea: 'email' }}>{ email }</div>
                <div className="GridLabel" style={{ gridArea: 'usernameLabel' }} onClick={() => changeFocus('')}>User Name</div>
                <div className="GridText" style={{ gridArea: 'username' }}>
                    <EditText
                        text={ username }
                        onClick={() => changeFocus('username')}
                        onBlur={() => saveUsername()}
                        updateText={usernameChanged}
                        editEnabled={editing === 'username'}
                        error={usernameError}
                    />
                </div>
            </div>
            <div className="ProfileRooms">
                <div className="ProfileRoomsHeader">Rooms</div>
                <Rooms />
            </div>
            <div className="ProfileFooter">
                <button className="Button" onClick={logout}>Logout</button>
            </div>
        </div>
    )
}