import { useEffect, useState } from 'react'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { albumInfo } from '../api/Api'
import useQueueTrack from '../hooks/useQueueTrack'
import { hoursMinsSecs } from '../util/time'
import Spinner from '../widgets/Spinner'
import TrackControls from './TrackControls'

export default function AlbumView(props) {

    const appState = useAppContext()
    const dispatch = useAppDispatch()
    const queueTrack = useQueueTrack()

    const { mainSheetState: { data: sourceTrack } } = appState

    const [albumData, setAlbumData] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const update = async () => {
            const info = await albumInfo(sourceTrack.pk, sourceTrack.artist, sourceTrack.album)
            setLoading(false)
            setAlbumData(info)
        }
        update()
    }, [sourceTrack])

    const tracks = sortedTracks().map( (track, index) => [
        <div className={ 'AlbumTrackNumber' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 1 }} key={`${track.hash}-album-a`}>{ trackNumber(track) }</div>,
        <div className={ 'AlbumTrackDuration' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 2 }} key={`${track.hash}-album-b`}>{ hoursMinsSecs(track.duration) }</div>,
        <div className={ 'AlbumTrackControls' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 3 }} key={`${track.hash}-album-c`}><TrackControls track={track} /></div>,
        <div className={ 'AlbumTrackTitle' + (index % 2 === 0 ? ' Even' : ' Odd') } style={{ gridRow: index + 1, gridColumn: 4 }} key={`${track.hash}-album-d`}><div className="NavLink" onClick={() => dispatch(Actions.showSheet({ action: 'showAlbum', data: track }))}>{track.title}</div></div>
    ])

    let trackList
    if (loading) trackList = <div className="AlbumLoading"><Spinner/></div>
    else trackList = <div className="AlbumGrid">{ tracks }</div>

    function sortedTracks() {
        return albumData?.tracks?.sort((a, b) => (Number(trackNumber(a)) < Number(trackNumber(b))) ? -1 : 1 ) ?? []
    }

    function trackNumber(track) {
        return track.track?.split(/\//)[0] ?? ''
    }

    function queueAll() {
        sortedTracks().forEach( track => queueTrack(track) )
        dispatch(Actions.showToast({ content: <span>Added all tracks from <b>{ sourceTrack.album }</b></span> }))
    }

    return (
        <div className="AlbumView">
            <div className="AlbumViewHeader">
                <span className="AlbumTitle">{ sourceTrack.album }</span>
                <span className="AlbumSeparator">by</span>
                <span className="AlbumArtist">{ sourceTrack.artist }</span>
            </div>
            <div className="AlbumGroupControls">
                <div className="AlbumGroupControlLabel">Queue All:</div>
                <div><span className="material-symbols-outlined TrackControlButton" onClick={queueAll}>playlist_add</span></div>
            </div>
            <div className="AlbumViewContent">
                { trackList }
            </div>
        </div>
    )
}
