import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function Queue(props) {

    const appState = useAppContext()
    const dispatch = useAppDispatch()

    const { playMode, queue, rooms, connectedRoom, user } = appState
    const isAdmin = (playMode !== 'room') || rooms?.find( room => room.id === connectedRoom )?.admins.find( admin => admin.id === user?.id )

    const queueSection = queue.map( (entry, index) => [
        <div key={`${entry.track.hash}-q${index}-p`} className={'QueueStatusIcon' + (index % 2 === 0 ? ' Even' : ' Odd')} style={{ gridRow: index + 1, gridColumn: 1 }}>
            { index === 0 ? <span className="material-symbols-outlined">brand_awareness</span> : '' }
        </div>,
        <div key={`${entry.track.hash}-q${index}`} className={'QueueTrack' + (index % 2 === 0 ? ' Even' : ' Odd')} style={{ gridRow: index + 1, gridColumn: 2 }}>
            <div className="TrackTitle" onClick={() => showTrack(entry.track)}>{ entry.track.title }</div>
            <div className="TrackSeparator">by</div>
            <div className="TrackArtist">{ entry.track.artist }</div>
        </div>,
        <div key={`${entry.track.hash}-q${index}-c`} className={'QueueTrackControls' + (index % 2 === 0 ? ' Even' : ' Odd')} style={{ gridRow: index + 1, gridColumn: 3 }}>
            { isAdmin ? <span
                className="TrackControlButton material-symbols-outlined"
                style={{ marginRight: 0 }}
                onClick={() => removeTrack(index)}>delete</span>
                : null }
        </div>
    ])

    function removeTrack(index) {
        if (playMode === 'room') {
            const entry = queue[index]
            global.chatRoom.removeTrack(entry)
        } else {
            dispatch(Actions.removeFromQueue(index))
        }
    }

    function showTrack(track) {
        dispatch(Actions.showSheet({ action: 'showTrack', data: track }))
    }

    return (
        <div className="Queue">
            { queueSection }
        </div>
    )
}