
export function hoursMinsSecs(input) {
    const totalSeconds = Number(input)
    if (isNaN(totalSeconds)) return '0:00'
    const secs = Math.floor(totalSeconds % 60)
    const mins = Math.floor(totalSeconds / 60)
    const hours = Math.floor(totalSeconds / 3600)
    const hourStr = hours ? `${hours}:` : ''
    const minStr = (hours && mins < 10 ? '0' : '') + `${mins}:`
    const secStr = secs < 10 ? `0${secs}` : `${secs}`
    return `${hourStr}${minStr}${secStr}`
}

export function idleTime(user) {
    return hoursMinsSecs(Math.round((Date.now() - user.updated) / 1000))
}
