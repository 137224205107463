import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function AlertModal(props) {

    const dispatch = useAppDispatch()
    const { text, okText } = props

    function ok() {
        dispatch(Actions.dismissMainModal())
    }

    return (
        <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp">
                <div>{ text }</div>
                <div className="ModalButtons">
                    <button style={{ marginRight: '10px' }} onClick={ok}>{ okText }</button>
                </div>
            </div>
        </div>
    )
}