import { getOrFetchAccessToken, logout } from '../api/Api'

export default class WebSocketManager {

    socket = null

    constructor(onConnected, onDisconnected, onData) {
        this.onConnected = onConnected
        this.onDisconnected = onDisconnected
        this.onData = onData
    }

    close = () => {
        this.socket?.close()
        this.socket = null
    }

    connect = async (roomId) => {
        this.roomId = roomId
        this.socket?.close()
        const token = await getOrFetchAccessToken()
        if (!token) {
            logout()
            return
        }
        const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL, ['tunistry', `auth-${token}`, `room-${roomId}`])
        ws.addEventListener('error', this.onError)
        ws.addEventListener('close', this.onClose)
        ws.addEventListener('open', this.onOpen)
        ws.addEventListener('message', this.onMessage)
        this.socket = ws
    }

    onClose = (e) => {
        console.log(`websocket closed: ${JSON.stringify(e)}`)
        this.onDisconnected?.()
    }

    onError = (e) => {
        console.log(`websocket error: ${JSON.stringify(e)}`)
    }

    onMessage = (e) => {
        try {
            const data = JSON.parse(e.data)
            console.log(`websocket message: ${JSON.stringify(data)}`)
            this.onData?.(data)
        } catch (err) {
            console.error(`error processing websocket message: ${err}`)
        }
    }

    onOpen = (data) => {
        console.log(`websocket opened: ${JSON.stringify(data)}`)
        this.onConnected?.()
    }

    sendMessage = (message) => {
        console.log(`webSocket: sending message: ${JSON.stringify(message)}`)
        this.socket?.send(JSON.stringify(message))
    }
}
