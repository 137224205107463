import { useState } from 'react'
import Tag from './Tag'

export default function TagView(props) {

    const [newTag, setNewTag] = useState('')

    const addButton = props.editing ? (
        <div className="TagViewAdd">
            <input
                className="TagViewInput"
                type="text" value={newTag}
                onChange={updateNewTag}
                onKeyDown={handleKeyPress}
                placeholder="new-tag"
            />
            <span className="material-symbols-outlined Button" onClick={addTag}>add_box</span>
        </div>
    ) : null

    function addTag() {
        if (props.onAddTag && newTag) props.onAddTag(newTag)
        setNewTag('')
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') addTag()
    }

    function updateNewTag(e) {
        const text = e.target.value.replaceAll(/[^a-zA-Z0-9\-_]/g, '')
        setNewTag(text)
    }

    return (
        <div className="TagView" onClick={props.onClick}>
            { addButton }
            { props.tags?.map( tag => <Tag tag={tag} key={tag} editing={props.editing} onRemove={props.onRemoveTag}>{ tag }</Tag>) }
        </div>
    )
}
