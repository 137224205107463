import { useEffect, useState } from 'react'
import { acceptInvite, declineInvite, getInvites, getRoomData } from '../api/Api'
import Spinner from '../widgets/Spinner'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function Rooms() {

    const dispatch = useAppDispatch()
    const appState = useAppContext()
    const { rooms, activeTab } = appState

    const [invites, setInvites] = useState()
    const [loading, setLoading] = useState('')

    useEffect(() => {
        async function updateRooms() {
            const [rooms, inviteData] = await Promise.all([getRoomData(), getInvites()])
            dispatch(Actions.setRooms(rooms))
            setInvites(inviteData)
        }
        if (activeTab === 'profile')
            updateRooms()
    }, [activeTab, dispatch])

    async function accept(invite) {
        setLoading(invite.roomId)
        setInvites(await acceptInvite(invite.roomId, invite.email))
        dispatch(Actions.setRooms(await getRoomData()))
        setLoading('')
    }

    async function decline(invite) {
        setLoading(invite.roomId)
        setInvites(await declineInvite(invite.roomId, invite.email))
        dispatch(Actions.setRooms(await getRoomData()))
        setLoading('')
    }

    const createRoom = <div className="RoomButtons"><button className="Button" onClick={() => dispatch(Actions.showSheet({ action: 'showCreateRoom' }))}>Create Room</button></div>

    let roomDivs
    if (rooms.length > 0) {
        roomDivs =
            <div className="RoomGrid">
                <div className="RoomGridHeading" style={{ gridColumn: 1 }}>Name</div>
                <div className="RoomGridHeading" style={{ gridColumn: 2 }}>Topic</div>
                <div className="RoomGridHeading" style={{ gridColumn: 3 }}>Members</div>
                { rooms.map( (room, index) => [
                    <div key={`roomname-${index}`} className="RoomGridName"><span className="Link" onClick={() => dispatch(Actions.showSheet({ action: 'showRoomDetails', data: room }))}>{room.name}</span></div>,
                    <div key={`roomtopic-${index}`} className="RoomGridTopic">{room.topic}</div>,
                    <div key={`roommembers-${index}`} className="RoomGridMembers">{room.members.length}</div>,
                ])
            }</div>
    } else {
        roomDivs = <div>You aren't a member of any rooms.</div>
    }

    let inviteSection
    if (invites?.length) {
        inviteSection = (
            <div className="RoomInvites">
                <div className="RoomInviteHeader">Invitations</div>
                <div className="RoomInviteGrid">
                    { invites?.map( (invite, index) => [
                        <div className="RoomInviteGridDiv" key={`${invite.roomId}-1`} style={{ gridRow: index, gridColumn: 1 }}>{ invite.issuer.username }</div>,
                        <div className="RoomInviteGridDiv"  key={`${invite.roomId}-2`} style={{ gridRow: index, gridColumn: 2 }}>{ invite.roomName }</div>,
                        <div className="RoomInviteGridDiv"  key={`${invite.roomId}-3`} style={{ gridRow: index, gridColumn: 3 }}>
                            <button disabled={loading} className="RoomInviteAcceptButton" onClick={() => accept(invite)}>Accept</button>
                            <button disabled={loading} className="RoomInviteDeclineButton" onClick={() => decline(invite)}>Decline</button>
                            { loading === invite.roomId ? <Spinner /> : null }
                        </div>
                    ]) }
                </div>
            </div>
        )
    }

    return (
        <div className="Rooms">
            { roomDivs }
            { inviteSection }
            { createRoom }
        </div>
    )
}
