import { useState } from 'react'
import { createAccountWithEmail } from '../api/Api'
import Spinner from '../widgets/Spinner'

export default function EmailCreateAccountModal(props) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirm, setConfirm] = useState('')
    const [showingSpinner, showSpinner] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [abortController] = useState(new AbortController())

    function cancel(e) {
        props.show('')
    }

    function confirmUpdated(e) {
        setErrorMessage('')
        setConfirm(e.target.value)
    }

    async function createAccount(e) {
        const emailProblem = validateEmail()
        if (emailProblem) {
            setErrorMessage(emailProblem)
            return
        }
        const passwordProblem = validatePassword()
        if (passwordProblem) {
            setErrorMessage(passwordProblem)
            return
        }
        showSpinner(true)
        try {
            const response = await createAccountWithEmail(email, password, abortController.signal)
            showSpinner(false)
            if (response.ok) {
                props.show('verifyEmail')
            } else {
                const err = await response.json()
                setErrorMessage(err.error)
            }
        } catch (err) {
            showSpinner(false)
            setErrorMessage(`Something went wrong: ${err.message}`)
        }
    }

    function emailUpdated(e) {
        setErrorMessage('')
        setEmail(e.target.value)
    }

    function logIn(e) {
        if (showingSpinner) {
            cancel()
        }
        props.show('emailLogin')
    }

    function passwordUpdated(e) {
        setErrorMessage('')
        setPassword(e.target.value)
    }

    function validateEmail() {
        const validEmailRegex = /^\S+@\S+\.\S+$/
        if (!validEmailRegex.test(email)) return 'Invalid email address.'
        return null
    }

    function validatePassword() {
        if (password.length < 8) return 'Your password must be at least 8 characters long.'
        if (password !== confirm) return 'Your password and the confirmation don\'t match.'
        return null
    }

    return (
        <div className="Dimmer">
            <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp">
                <div style={{ display: 'grid', gridTemplateColumns: '100px 1fr', gridColumnGap: '5px', gridRowGap: '3px' }}>
                    <div style={{ gridRow: 1, gridColumn: 1, textAlign: 'right' }}>Email:</div>
                    <div style={{ gridRow: 1, gridColumn: 2, textAlign: 'left' }}>
                        <input type="text" style={{ width: '100%' }} value={email} onChange={emailUpdated}/>
                    </div>
                    <div style={{ gridRow: 2, gridColumn: 1, textAlign: 'right' }}>Password:</div>
                    <div style={{ gridRow: 2, gridColumn: 2, textAlign: 'left' }}>
                        <input type="password" style={{ width: '100%' }} value={password} onChange={passwordUpdated}/>
                    </div>
                    <div style={{ gridRow: 3, gridColumn: 1, textAlign: 'right' }}>Confirm:</div>
                    <div style={{ gridRow: 3, gridColumn: 2, textAlign: 'left' }}>
                        <input type="password" style={{ width: '100%' }} value={confirm} onChange={confirmUpdated}/>
                    </div>
                    <div style={{ gridRow: 4, gridColumn: 1, textAlign: 'right' }}>
                        <div style={{ height: '25px', marginTop: '8px', marginRight: '10px', marginBottom: '0px' }}>
                            { showingSpinner ? <Spinner /> : null }
                        </div>
                    </div>
                    <div style={{ gridRow: 4, gridColumn: 2, marginTop: '10px', textAlign: 'left' }}>
                        <button style={{ marginRight: '10px' }} disabled={showingSpinner} onClick={cancel}>Cancel</button>
                        <button style={{ marginRight: '10px' }} disabled={showingSpinner} onClick={createAccount}>Create Account</button>
                    </div>
                    <div style={{ gridRow: 5, gridColumn: 2, textAlign: 'right' }}>
                        <p className="Link" style={{ fontSize: '12px' }} onClick={logIn}>Already have an account?</p>
                    </div>
                    { errorMessage.length > 0 ?
                        <div style={{ gridRow: 6, gridColumnStart: 1, gridColumnEnd: 3, textAlign: 'center' }}>
                            <p className="ErrorMessage">{ errorMessage }</p>
                        </div>
                      : null }
                </div>
            </div>
            </div>
        </div>
    )
}