export default function VerificationSucceededModal(props) {

    function logIn(e) {
        window.location.search = ''
    }

    return (
        <div className="Dimmer">
            <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp" onClick={(e)=>{e.stopPropagation()}}>
                <div style={{ textAlign: 'center' }}>
                    <p className="Message">Verification complete!  You may now log in.</p>
                    <div>
                        <button onClick={logIn}>Log In</button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
