import { useState } from 'react'
import Spinner from '../widgets/Spinner'
import { getRoomData, getUserData, makeRoom } from '../api/Api'
import EditText from '../widgets/EditText'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function CreateRoom() {

    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState()
    const [name, setName] = useState('Listening Room')
    const [topic, setTopic] = useState('Welcome to Tunistry')

    const spinner = loading ? <Spinner /> : null

    async function makeUserRoom() {
        setLoading(true)
        await makeRoom(name, topic).then(() => Promise.all([getUserData(), getRoomData()]))
        setLoading(false)
        dispatch(Actions.dismissMainSheet())
    }

    return (
        <div>
            <div className="RoomDetailsGrid">
                <div className="GridLabel" style={{ gridArea: "nameLabel" }}>Name</div>
                <div className="GridText" style={{ gridArea: "name" }}><EditText editEnabled={true} text={name} updateText={(e) => setName(e.target.value)}/></div>
                <div className="GridLabel" style={{ gridArea: "topicLabel" }}>Topic</div>
                <div className="GridText" style={{ gridArea: "topic" }}><EditText editEnabled={true} text={topic} updateText={(e) => setTopic(e.target.value)}/></div>
            </div>
            <div className="CreateRoomButton">
            <button disabled={loading} onClick={makeUserRoom}>Create Room</button>
            { spinner }
            </div>
        </div>
    )
}
