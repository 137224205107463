import {useState} from 'react'
import { Dashboard } from '@uppy/react'
import S3Uploader from './S3Uploader'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'

export default function Upload() {

    const [uploader] = useState(() => new S3Uploader())

    return (
        <div className="UploadPage">
            <Dashboard uppy={uploader.uppy} theme="dark" />
        </div>
    )
}