import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function Toast(props) {

    const dispatch = useAppDispatch()
    const appState = useAppContext()
    const show = appState.showToast
    const content = appState.toastState?.content

    function dismiss() {
        dispatch(Actions.dismissToast())
    }

    return (
        <div className={`Toast ${show ? 'show' : ''}`}>
            <div className="ToastInterior">
                <div className="ToastContent">{ content }</div>
                <div className="ToastControls">
                    <span className="material-symbols-outlined Button" onClick={dismiss}>close</span>
                </div>
            </div>
        </div>
    )
}
