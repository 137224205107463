
export default function Person(props) {
    const removeButton = props.editing ? (
        <div className="PersonEditingRemoveButton" onClick={() => props.onRemove(props.person)}>
            <span className="material-symbols-outlined Button">cancel</span>
        </div>
    ) : null

    return (
        <div className={`Person ${props.editing ? 'PersonEditing' : ''}`}>
            { props.person.username }
            { removeButton }
        </div>
    )
}
