
export default function Modal(props) {

    return (
        <div className="Modal" style={{ visibility: props.open ? 'visible' : 'hidden' }}>
            <div className="ModalContent">
                { props.children }
            </div>
        </div>
    )
}
